import TRACE_API from "@/api/TRACE_API.js";


export default {
    name: "KPIs",

    components: {},

    data() {
        return {
			KPIs:null
		}
	},

	mounted() {
        this.Clear();
    },

	methods: {

        Clear() {
			this.KPIs = null;
		},

		async GetKPIs () {
			this.KPIs = await TRACE_API.GetKPIs()
		}
	}
}